import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import cn from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'

import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import Typography from '../components/Common/Typography'
import { Button } from '../components/Common/Button'
import ModalIframe from '../components/Common/ModalIframe'
import { useTranslations } from '../hooks/use-translations'

import * as s from '../pages/news.module.scss'

import ModalImage from '../components/Common/ModalImage'
import YoutubePlayIcon from '../svg/youtube-play-icon'

const SeptemberDigest2024 = () => {
  const { getPath } = useTranslations()
  const images = useStaticQuery(imagesQuery)
  const [imageData, setImageData] = React.useState<{
    title: string
    image: string
  }>()
  const [modalVideoData, setModalVideoData] = React.useState<{
    title: string
    src: string
  }>()
  return (
    <>
      <SEO
        title="Digest of the September updates on SIGNAX products"
        description="SIGNAX September 2024 Digest of updates and product materials, including SIGNAX DOCS CDE, INSPECTION - remote construction control service, DASHBOARD project management software and TOOLS solution for BIM management automation."
      />
      <NewsSectionBlock date="September 20, 2024">
        <Typography variant="h1" color="blue">
          September Digest SIGNAX
        </Typography>
        <Typography variant="h2" size={24}>
          Product Updates
        </Typography>
        <Typography variant="body1">
          September brought a wave of fresh{' '}
          <span style={{ fontWeight: 500 }}>content</span> and{' '}
          <span style={{ fontWeight: 500 }}>product updates</span>! We unveiled
          four new product videos on our social media, along with two engaging
          #DevNews posts that spotlight our{' '}
          <span style={{ fontWeight: 500 }}>latest improvements</span>. A major
          milestone that we achieved -{' '}
          <span style={{ fontWeight: 500 }}>launch of our new desktop app</span>{' '}
          for DOCS CDE. In addition, we published an{' '}
          <span style={{ fontWeight: 500 }}>insightful article</span> outlining
          our vision for the industry's future and who’s best positioned to meet
          its evolving needs.
        </Typography>
        <Typography variant="body1">
          Catch all the details in this month’s digest and be sure to check out
          our latest article below:
        </Typography>
        <div className={cn(s.contentWrap)}>
          <div className={s.textWrap}>
            <Typography variant="body1" size={18}>
              In today's fast-paced construction world, every company needs a
              digital transformation — and that’s where the{' '}
              <span style={{ fontWeight: 500 }}>Digital Engineer</span> steps
              in! This{' '}
              <span style={{ fontWeight: 500 }}>indispensable role</span> is
              streamlining data management, optimizing and improving workflows,
              and uniting teams. By implementing{' '}
              <span style={{ fontWeight: 500 }}>BIM on site</span> and driving
              technological innovation, they are changing the way construction
              projects are managed and executed.
            </Typography>
            <Typography variant="body1" size={18}>
              Learn more about Digital engineers and how they are shaping the{' '}
              <span style={{ fontWeight: 500 }}>future of construction</span>:
            </Typography>
            <Button
              size="small"
              onClick={() => navigate(getPath('/digital-engineer/'))}
            >
              Open Article
            </Button>
          </div>
          <div
            className={s.imageClickable}
            onClick={() =>
              setImageData({
                title: 'Digital engineer Article',
                image: 'image1',
              })
            }
          >
            <GatsbyImage
              image={images.image1Preview.childImageSharp.gatsbyImageData}
              alt="Learn more about Digital engineers"
            />
          </div>
        </div>
        <Typography variant="h3" size={18}>
          SIGNAX HUB
        </Typography>
        <Typography variant="body1">
          <span style={{ fontWeight: 500 }}>Project management</span> starts
          with HUB. From setting up projects to managing licenses and roles,
          we’ve made project administration smoother than ever. Our team is
          happy to show the ease of use and{' '}
          <span style={{ fontWeight: 500 }}>
            flexibility of SIGNAX license management
          </span>{' '}
          in our new product video.
        </Typography>
        <Typography variant="body1">
          Watch our new video and contact us if you want to{' '}
          <span style={{ fontWeight: 500 }}>streamline your projects</span>.
        </Typography>
        <div
          className={s.youtubeVideo}
          onClick={() =>
            setModalVideoData({
              title: 'SIGNAX HUB. Project administration',
              src: 'https://youtube.com/embed/aOhSbimWmas?si=sDEvcpydNt9zEsjs',
            })
          }
        >
          <GatsbyImage
            image={images.videoPreview1.childImageSharp.gatsbyImageData}
            alt="SIGNAX HUB. Project administration"
          />
          <YoutubePlayIcon />
        </div>
        <Typography variant="h3" size={18}>
          SIGNAX DOCS
        </Typography>
        <Typography variant="body1">
          We’ve hit a major milestone — SIGNAX DOCS is now available as a
          desktop app!{' '}
          <span style={{ fontWeight: 500 }}>
            Install DOCS Disk on your computer
          </span>{' '}
          to connect your desktop environment with a SIGNAX account and have all
          of your{' '}
          <span style={{ fontWeight: 500 }}>
            project data just a click away
          </span>
          . This solution is designed for working from your computer using
          native applications with opening file formats, including office
          documents, schedules, CAD and BIM Files. This way, users can work
          through familiar interfaces while files{' '}
          <span style={{ fontWeight: 500 }}>automatically sync</span> in SIGNAX
          DOCS.
        </Typography>
        <Typography variant="body1">
          Don’t miss our latest product video to see how DOCS Disk can{' '}
          <span style={{ fontWeight: 500 }}>transform your workflow</span>:
        </Typography>
        <div
          className={s.youtubeVideo}
          onClick={() =>
            setModalVideoData({
              title: 'SIGNAL DOCS Disk. Desktop solution',
              src: 'https://youtube.com/embed/FG2iMtOSE8E?si=2eB2oussT6RF_ndY',
            })
          }
        >
          <GatsbyImage
            image={images.videoPreview2.childImageSharp.gatsbyImageData}
            alt="SIGNAL DOCS Disk. Desktop solution"
          />
          <YoutubePlayIcon />
        </div>
        <Typography variant="body1">
          In another video we highlight valuable use cases from our portfolio of
          using <span style={{ fontWeight: 500 }}>Point Clouds</span> in DOCS.
          Our customer optimized{' '}
          <span style={{ fontWeight: 500 }}>material management</span> by
          utilizing point clouds. Additionally, point clouds were used as proof
          of work during meetings.
        </Typography>
        <Typography variant="body1">
          Discover more about this and other{' '}
          <span style={{ fontWeight: 500 }}>use cases</span> in the video:
        </Typography>
        <div
          className={s.youtubeVideo}
          onClick={() =>
            setModalVideoData({
              title: 'SIGNAL DOCS. Point cloud use cases',
              src: 'https://youtube.com/embed/VOEhGladE2g?si=_UuSnttAMBvAXnmG',
            })
          }
        >
          <GatsbyImage
            image={images.videoPreview3.childImageSharp.gatsbyImageData}
            alt="SIGNAL DOCS. Point cloud use cases"
          />
          <YoutubePlayIcon />
        </div>
        <Typography variant="h3" size={18}>
          SIGNAX DASHBOARD
        </Typography>
        <div className={cn(s.contentWrap)}>
          <div className={s.textWrap}>
            <Typography variant="body1" size={18}>
              We’ve made some updates to{' '}
              <span style={{ fontWeight: 500 }}>DASHBOARD</span> by{' '}
              <span style={{ fontWeight: 500 }}>
                renaming and reorganizing the card types!
              </span>{' '}
              Here’s the new structure:
            </Typography>
            <Typography variant="body1" size={18}>
              <ul>
                <li>
                  <span style={{ fontWeight: 500 }}>10 Group</span> - General,
                  manually filled
                </li>
                <li>
                  <span style={{ fontWeight: 500 }}>20 Group</span> - Budget
                </li>
                <li>
                  <span style={{ fontWeight: 500 }}>30 Group</span> - Deadlines
                </li>
                <li>
                  <span style={{ fontWeight: 500 }}>40 Group</span> - Scope
                </li>
                <li>
                  <span style={{ fontWeight: 500 }}>50 Group</span> - BIM
                </li>
                <li>
                  <span style={{ fontWeight: 500 }}>60 Group</span> - Internal
                  SIGNAX integrations
                </li>
                <li>
                  <span style={{ fontWeight: 500 }}>90 Group</span> - Other
                  integrations
                </li>
              </ul>
            </Typography>
            <Typography variant="body1" size={18}>
              We also plan to hide unused cards and re-sort the list. Don’t
              worry — your dashboards will stay the same, just with updated card
              type names!
            </Typography>
          </div>
          <div
            className={s.imageClickable}
            onClick={() =>
              setImageData({
                title: 'DevNews DASHBOARD',
                image: 'image2',
              })
            }
          >
            <GatsbyImage
              image={images.image2Preview.childImageSharp.gatsbyImageData}
              alt="DevNews DASHBOARD"
            />
          </div>
        </div>
        <Typography variant="h3" size={18}>
          SIGNAX INSPECTION
        </Typography>
        <Typography variant="body1">
          The video{' '}
          <span style={{ fontWeight: 500 }}>
            highlights the QA/QC functions
          </span>{' '}
          of SIGNAX INSPECTION. We also showcased a new Inquiry function that
          would be valuable for{' '}
          <span style={{ fontWeight: 500 }}>tracking contractors' tasks.</span>{' '}
          We are proud that this product video has become popular, so don't miss
          it!
        </Typography>
        <div
          className={s.youtubeVideo}
          onClick={() =>
            setModalVideoData({
              title: 'SIGNAX INSPECTION. QA/QC and Inquiries workflow',
              src: 'https://youtube.com/embed/Wgcu1JgSAN4?si=RCZ_tTIxxIefdWTU',
            })
          }
        >
          <GatsbyImage
            image={images.videoPreview4.childImageSharp.gatsbyImageData}
            alt="SIGNAX INSPECTION. QA/QC and Inquiries workflow"
          />
          <YoutubePlayIcon />
        </div>
      </NewsSectionBlock>
      {modalVideoData && (
        <ModalIframe
          title={modalVideoData.title}
          src={modalVideoData.src}
          srcTitle={modalVideoData.title}
          onClose={() => setModalVideoData(undefined)}
        />
      )}
      {imageData && (
        <ModalImage
          title={imageData.title}
          onClose={() => setImageData(undefined)}
        >
          <GatsbyImage
            image={images[imageData.image].childImageSharp.gatsbyImageData}
            alt={imageData.title}
            imgStyle={{
              objectFit: 'contain',
              objectPosition: '50% 50%',
              maxHeight: ' calc(100vh - 100px)',
            }}
          />
        </ModalImage>
      )}
    </>
  )
}

const imagesQuery = graphql`
  query {
    image1Preview: file(
      relativePath: { eq: "news/september-digest-2024/image-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 564, placeholder: BLURRED)
      }
    }
    image1: file(
      relativePath: { eq: "news/september-digest-2024/image-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    image2Preview: file(
      relativePath: { eq: "news/september-digest-2024/image-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 564, placeholder: BLURRED)
      }
    }
    image2: file(
      relativePath: { eq: "news/september-digest-2024/image-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    videoPreview1: file(
      relativePath: { eq: "news/september-digest-2024/video-preview-1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
    videoPreview2: file(
      relativePath: { eq: "news/september-digest-2024/video-preview-2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
    videoPreview3: file(
      relativePath: { eq: "news/september-digest-2024/video-preview-3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
    videoPreview4: file(
      relativePath: { eq: "news/september-digest-2024/video-preview-4.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
  }
`

export default SeptemberDigest2024
